import app from 'flarum/app';
import Post from 'flarum/components/Post';
import {ucfirst} from 'flarum/utils/string';
import icon from 'flarum/helpers/icon';

/* global m */

const translationPrefix = 'kilowhat-wordpress.forum.summary-post.';

export default class WordpressSummaryPost extends Post {
    attrs() {
        const attrs = super.attrs();

        attrs.className = (attrs.className || '') + ' KilowhatWordpressSummaryPost';

        return attrs;
    }

    content() {
        const content = this.props.post.content();

        const linkAttrs = {
            href: content.url,
            target: '_blank',
            rel: 'noopener',
        };

        return super.content().concat([
            m('.KilowhatWordpressSummaryPost-Article', [
                content.url ? m('a.Button.KilowhatWordpressSummaryPost-Link', linkAttrs, [
                    app.translator.trans(translationPrefix + 'blog_link'),
                    ' ',
                    icon('fas fa-external-link-alt'),
                ]) : null,
                m('p.KilowhatWordpressSummaryPost-Wordpress', app.translator.trans(translationPrefix + 'head_label')),
                content.title ? m('h1', content.title) : null,
                content.body ? m.trust(content.body) : null,
                content.isExcerpt ? m('.KilowhatWordpressSummaryPost-ExcerptMore', m('a.Button', linkAttrs, [
                    app.translator.trans(translationPrefix + 'read_more'),
                ])) : null,
            ]),
        ]);
    }
}
