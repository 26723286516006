import app from 'flarum/app';
import {extend} from 'flarum/extend';
import Page from 'flarum/components/Page';
import WelcomeModal from './components/WelcomeModal';

export default function () {
    let initialized = false;

    extend(Page.prototype, 'init', function () {
        if (initialized) {
            return;
        }

        if (app.forum.attribute('kilowhatWordpressShowWelcome')) {
            app.modal.show(new WelcomeModal());
        }

        initialized = true;
    });
}
