import app from 'flarum/app';
import {extend} from 'flarum/extend';
import EditUserModal from 'flarum/components/EditUserModal';
import Alert from 'flarum/components/Alert';

/* global m */

const translationPrefix = 'kilowhat-wordpress.forum.edit-user-modal.';

export default function () {
    extend(EditUserModal.prototype, 'fields', function (items) {
        if (!this.props.user.attribute('kilowhatWordpressIsLinked')) {
            return;
        }

        items.add('kilowhat-wordpress-warning', m('.Form-group', Alert.component({
            dismissible: false,
            children: [
                app.translator.trans(translationPrefix + 'warning'),
                ' ',
                m('a.Button', {
                    href: this.props.user.attribute('kilowhatWordpressEditLink'),
                    target: '_blank',
                    rel: 'noopener',
                }, app.translator.trans(translationPrefix + 'edit_in_wordpress')),
            ],
        })), 100);

        if (items.has('email')) {
            const emailField = items.get('email');

            if (Array.isArray(emailField.children)) {
                if (
                    !this.kilowhatWordpressShowFields && // Only make field disabled if we don't show fields
                    emailField.children.length > 1 && // .Form-group children
                    emailField.children[1] &&
                    Array.isArray(emailField.children[1].children) &&
                    emailField.children[1].children.length > 1 && // div children
                    emailField.children[1].children[1] &&
                    emailField.children[1].children[1].tag === 'input'
                ) {
                    if (!emailField.children[1].children[1].attrs) {
                        emailField.children[1].children[1].attrs = {};
                    }
                    emailField.children[1].children[1].attrs.disabled = true;
                }

                emailField.children.push(m('p.helpText', app.translator.trans(translationPrefix + 'should_not_edit_email', {
                    a: m('a', {
                        onclick: () => {
                            this.kilowhatWordpressShowFields = true;
                            this.kilowhatWordpressUnlink = true;
                        },
                    }),
                })));
            }
        }

        if (!this.kilowhatWordpressShowFields && items.has('password')) {
            const passwordField = items.get('password');

            if (
                Array.isArray(passwordField.children) &&
                passwordField.children.length > 1 && // .Form-group children
                passwordField.children[1] &&
                Array.isArray(passwordField.children[1].children) &&
                passwordField.children[1].children.length > 1 && // div children
                passwordField.children[1].children[1] &&
                Array.isArray(passwordField.children[1].children[1].children) &&
                passwordField.children[1].children[1].children.length > 0 && // label children
                passwordField.children[1].children[1].children[0] &&
                passwordField.children[1].children[1].children[0].tag === 'input'
            ) {
                if (!passwordField.children[1].children[1].children[0].attrs) {
                    passwordField.children[1].children[1].children[0].attrs = {};
                }
                passwordField.children[1].children[1].children[0].attrs.disabled = true;
            }
        }

        if (this.kilowhatWordpressShowFields) {
            items.add('kilowhat-wordpress-unlink', m('.Form-group', [
                m('label.checkbox', [
                    m('input', {
                        type: 'checkbox',
                        onchange: e => {
                            this.kilowhatWordpressUnlink = e.target.checked;
                        },
                        checked: this.kilowhatWordpressUnlink,
                    }),
                    ' ',
                    app.translator.trans(translationPrefix + 'unlink'),
                ]),
                m('p.helpText', app.translator.trans(translationPrefix + 'unlink_help')),
            ]), 25);
        }
    });

    extend(EditUserModal.prototype, 'data', function (data) {
        if (this.kilowhatWordpressUnlink) {
            data.kilowhatWordpressUnlink = true;
        }
    });

    //TODO: add warning when deleting
}
