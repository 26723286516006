import app from 'flarum/app';
import Modal from 'flarum/components/Modal';
import Button from 'flarum/components/Button';

/* global m */

const translationPrefix = 'kilowhat-wordpress.forum.welcome-modal.';

export default class WelcomeModal extends Modal {
    init() {
        super.init();

        this.username = m.prop(app.session.user.username());
        this.loading = false;
    }

    title() {
        return app.translator.trans(translationPrefix + 'title');
    }

    content() {
        const message = app.forum.attribute('kilowhatWordpressWelcomeMessage');
        const usernameChoice = app.forum.attribute('kilowhatWordpressUsernameChoice');

        return m('.Modal-body', [
            message ? m('p', message.split('\n').map((text, index) => {
                if (index > 0) {
                    return [
                        m('br'),
                        text,
                    ];
                }

                return text;
            })) : m('p', app.translator.trans(translationPrefix + 'default_message')),
            usernameChoice ? m('.Form-group', [
                m('label', app.translator.trans(translationPrefix + 'username')),
                m('input.FormControl', {
                    type: 'text',
                    disabled: this.loading,
                    bidi: this.username,
                    minlength: 3,
                    maxlength: 30,
                    required: true,
                }),
            ]) : null,
            m('.Form-group', [
                Button.component({
                    type: 'submit',
                    loading: this.loading,
                    className: 'Button Button--primary',
                    children: app.translator.trans(translationPrefix + 'submit'),
                }),
                ' ',
                Button.component({
                    disabled: this.loading,
                    className: 'Button',
                    onclick() {
                        app.modal.close();
                    },
                    children: app.translator.trans(translationPrefix + 'not_now'),
                }),
            ]),
        ]);
    }

    onsubmit(e) {
        e.preventDefault();

        this.loading = true;

        app.request({
            method: 'POST',
            url: app.forum.attribute('apiUrl') + '/kilowhat/wordpress/activate',
            data: app.forum.attribute('kilowhatWordpressUsernameChoice') ? {
                username: this.username(),
            } : null,
        }).then(() => {
            this.loading = false;
            m.redraw();
            window.location.reload();
        }).catch(e => {
            this.loading = false;
            this.onerror(e);
        });
    }
}
