import app from 'flarum/app';
import {override} from 'flarum/extend';
import {getPlainContent} from 'flarum/utils/string';
import Post from 'flarum/models/Post';
import WordpressSummaryPost from './components/WordpressSummaryPost';

export default function () {
    app.postComponents.kilowhatWordpressPostSummary = WordpressSummaryPost;

    // Make contentHtml and contentPlain work like normal comment posts, so that summaries can work with them
    override(Post.prototype, 'contentHtml', function (original) {
        if (this.contentType() === 'kilowhatWordpressPostSummary') {
            const content = this.content();

            return content.body ? content.body : '';
        }

        return original();
    });
}
