import app from 'flarum/app';
import {extend} from 'flarum/extend';
import SettingsPage from 'flarum/components/SettingsPage';

/* global m */

export default function() {

    extend(SettingsPage.prototype, 'accountItems', function(items) {
        if (this.user.attribute('kilowhatWordpressIsLinked')) {
            items.remove('changeEmail');
            items.remove('changePassword');

            items.add('kilowhat-wordpress', m('a.Button', {
                href: app.forum.attribute('kilowhatWordpressProfileLink'),
            }, app.translator.trans('kilowhat-wordpress.forum.settings.edit_on_wordpress')));
        }
    });
}
