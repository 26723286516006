import app from 'flarum/app';
import addEditUserModalWarnings from './addEditUserModalWarnings';
import removeSettings from './removeSettings';
import addLoginButton from './addLoginButton';
import addWelcomeModal from './addWelcomeModal';
import addPostType from './addPostType';

app.initializers.add('kilowhat-wordpress', () => {
    addEditUserModalWarnings();
    addLoginButton();
    addPostType();
    addWelcomeModal();
    removeSettings();
});
