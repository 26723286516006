import app from 'flarum/app';
import {extend, override} from 'flarum/extend';
import HeaderSecondary from 'flarum/components/HeaderSecondary';
import LogInButtons from 'flarum/components/LogInButtons';
import Button from 'flarum/components/Button';
import IndexPage from 'flarum/components/IndexPage';
import DiscussionControls from 'flarum/utils/DiscussionControls';

/* global $ */

function openWordpressLoginModal() {
    const width = 380;
    const height = 500;
    const $window = $(window);

    window.open(app.forum.attribute('baseUrl') + '/auth/wordpress', 'logInPopup',
        `width=${width},` +
        `height=${height},` +
        `top=${$window.height() / 2 - height / 2},` +
        `left=${$window.width() / 2 - width / 2},` +
        'status=no,scrollbars=no,resizable=no');
}

function failPromiseAndOpenLogin(original) {
    if (app.forum.attribute('kilowhatWordpressOnly') && !app.session.user) {
        openWordpressLoginModal();

        const deferred = m.deferred();
        deferred.reject();
        return deferred.promise;
    } else {
        return original();
    }
}

export default function () {
    extend(LogInButtons.prototype, 'items', function (items) {
        items.add('kilowhat-wordpress', Button.component({
            className: 'Button LogInButton LogInButton--wordpress',
            icon: 'fab fa-wordpress',
            children: app.translator.trans('kilowhat-wordpress.forum.log_in.with_wordpress_button'),
            onclick: openWordpressLoginModal,
        }));
    });

    extend(HeaderSecondary.prototype, 'items', function (items) {
        if (app.session.user || !app.forum.attribute('kilowhatWordpressOnly')) {
            return;
        }

        items.remove('logIn');
        items.remove('signUp');

        items.add('kilowhat-wordpress', Button.component({
            className: 'Button Button--link',
            children: app.translator.trans('core.forum.header.log_in_link'),
            onclick: openWordpressLoginModal,
        }));
    });

    // Open our own Wordpress modal instead of the login modal if the user is logged out
    override(DiscussionControls, 'replyAction', failPromiseAndOpenLogin);
    override(IndexPage.prototype, 'newDiscussionAction', failPromiseAndOpenLogin);
}
